import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/normalize.css"
import "../utils/css/screen.css"
class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
  <br/> <br/>
        <h2 class="text-center m-b-0">Page is not found</h2>

        <h4 class="text-center m-t-0">Oh no! <Link to={`/`}>Go back home</Link></h4>
        <h4 class="text-center">¯\_(ツ)_/¯</h4><br/><br/>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
